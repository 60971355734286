import React, { useState, useEffect } from "react";
import { FormattedMessage } from "gatsby-plugin-intl";

import { getProposal } from "../services/getRequest";
import Layout from "../components/layout";
import _ from "lodash";

const Project = ({ location }) => {
  const [project, setProject] = useState({});
  const searchParams = new URLSearchParams(`${location.search}`);

  useEffect(() => {
    if (searchParams.has("id") && searchParams.get("id") !== NaN) {
      getProposal(searchParams.get("id")).then((project) => {
        setProject({
          all: project.data.items[0].column_values,
          updates: project.data.items[0].updates,
          name: project.data.items[0].name,
          impact: _.find(project.data.items[0].column_values, { id: "impact" })
            .text,
          projectStatus: _.find(project.data.items[0].column_values, {
            id: "status",
          }).text,
          timeline: _.find(project.data.items[0].column_values, {
            id: "timeline",
          }).text,
          team: _.find(project.data.items[0].column_values, { id: "dropdown4" })
            .text,
          overview: _.find(project.data.items[0].column_values, { id: "text" })
            .text,
          insight: _.find(project.data.items[0].column_values, {
            id: "dup__of_desired_outcome",
          }).text,
          outcome: _.find(project.data.items[0].column_values, { id: "text7" })
            .text,
          updateCount: project.data.items[0].updates.length,
        });
      });
    }
  }, []);
  const hasComments = project.updateCount > 0;
  return (
    <Layout>
      <h1 className="block-title">{project.name}</h1>
      <div className="badge bg-secondary">{project.projectStatus}</div>
      {project.team && (
        <div>
          <h2 className="anchor-title">
            <FormattedMessage id="project_team" defaultMessage="Project Team" />
          </h2>
          <p>
            <small>{project.team}</small>
          </p>
        </div>
      )}
      <h2 className="anchor-title">
        <FormattedMessage id="overview" defaultMessage="Overview" />
      </h2>
      <p>{project.overview}</p>
      <p>{project.insight}</p>
      <p>{project.outcome}</p>

      <h2>
        <FormattedMessage id="updates" defaultMessage="Updates" />
      </h2>
      {hasComments ? (
        <ul>
          {project.updates.map((update) => {
            return (
              <li className="card">
                <div className="card-body">
                  <p>{update.text_body}</p>
                  <footer className="blockquote-footer">
                    {update.creator.name}
                  </footer>
                </div>
                <div className="card-footer muted">{update.created_at}</div>
              </li>
            );
          })}
        </ul>
      ) : (
        <FormattedMessage
          id="no_comments"
          defaultMessage="There are no comments"
        />
      )}
    </Layout>
  );
};

export default Project;
